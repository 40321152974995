import fetch from './api';
import { API_SELFIE, getEnv } from './env';

const urlBackend = `${getEnv(API_SELFIE)}/pay-installments`;

// eslint-disable-next-line import/prefer-default-export
export const getLoansToPayInstallments = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/loans',
    method: 'get',
  });
