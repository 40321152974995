/* eslint-disable arrow-body-style */
const PayInstallmentFinalPage = () => {
  return (
    <>
      FINAL
    </>
  );
};

export default PayInstallmentFinalPage;
