import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import i18n from '../../../common/i18n';
import Header from '../../../components/common/Header';
import usePayInstallmentStyles from '../PayInstallmentStyles';
import { getEnv, URL_NEW_LOAN_FOR_NEW_CLIENT } from '../../../api/env';

const NoActiveLoan = () => {
  const classes = usePayInstallmentStyles();
  return (
    <>
      <Header title={i18n.PayInstallmentPage.title} />
      <Grid container style={{ marginTop: '32px', paddingLeft: '24px', paddingRight: '24px' }} justifyContent="center">
        <Grid item xs={12} md={8} className={classes.gap}>
          <img src="/images/no-active-loan.svg" alt="Sin prestamos" />
          <Typography style={{ marginTop: '12px', fontSize: '24px', fontWeight: 700 }}>{i18n.PayInstallmentPage.noActiveLoanTitle}</Typography>
          <Typography style={{ marginTop: '12px', fontSize: '16px' }}>{i18n.PayInstallmentPage.noActiveLoanDescription}</Typography>
          <Box className={classes.ctaContainer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => window.open(getEnv(URL_NEW_LOAN_FOR_NEW_CLIENT))}
            >
              {i18n.PayInstallmentPage.newLoanCTA}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NoActiveLoan;
