import { Switch, Route, Redirect } from 'react-router-dom';
import PayInstallmentPage from '../pages/payInstallment/PayInstallmentPage';
import { getPathRouteFromKey, PayInstallmentRoutesList } from '../utils/menuAndRoutesUtil';
import PayInstallmentSummaryPage from '../pages/payInstallment/PayInstallmentSummaryPage';
import PayInstallmentFinalPage from '../pages/payInstallment/PayInstallmentFinalPage';
import i18n from '../common/i18n';
import { PayInstallmentProvider } from '../contexts/PayInstallmentContext';

const PayInstallmentRouter = () => (
  <PayInstallmentProvider>
    <Switch>
      <Route exact path={PayInstallmentRoutesList.home}>
        <PayInstallmentPage />
      </Route>
      <Route exact path={PayInstallmentRoutesList.summary}>
        <PayInstallmentSummaryPage />
      </Route>
      <Route exact path={PayInstallmentRoutesList.final}>
        <PayInstallmentFinalPage />
      </Route>
      <Route>
        <Redirect from="*" to={getPathRouteFromKey(i18n.homeKey)} />
      </Route>
    </Switch>
  </PayInstallmentProvider>
);

export default PayInstallmentRouter;
