export const STATUS_TO_PAY = 'TO_PAY';
export const STATUS_UP_TO_DATE = 'UP_TO_DATE';
export const STATUS_DEBT = 'DEBT';

export const COLOR_BY_STATUS = {
  [STATUS_TO_PAY]: '#F5A623',
  [STATUS_UP_TO_DATE]: '#02C66A',
  [STATUS_DEBT]: '#D0013F',
};

export const DESCRIPTION_BY_STATUS = {
  [STATUS_TO_PAY]: 'A pagar',
  [STATUS_UP_TO_DATE]: 'Al día',
  [STATUS_DEBT]: 'En deuda',
};
