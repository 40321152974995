import {
  Redirect, Route, Switch,
} from 'react-router';
import { useEffect } from 'react';
import i18n from '../common/i18n';
import HowPaymentMethodOnline from '../components/HowPaymentMethodOnline';
import PaymentDebitCardForm from '../components/PaymentDebitCardForm';
import PaymentMethodSelectionOnline from '../components/PaymentMethodSelectionOnline';
import PaymentMPForm from '../components/PaymentMPForm';
import PaymentStatus from '../components/PaymentStatus';
import Claims from '../pages/claims';
import CustomerFreeDebt from '../pages/customerFreeDebt';
import CustomerInvoices from '../pages/customerInvoices';
import LoanInstallments from '../pages/loanInstallments';
import LoanPaymentVouchers from '../pages/loanPaymentVouchers';
import Logout from '../pages/login/logout';
import OtherServices from '../pages/otherServices';
import PayInstallment from '../pages/payInstallment';
import PaymentReceipt from '../pages/paymentReceipt';
import PrePaidLoan from '../pages/prePaidLoan';
import UserProfile from '../pages/userProfile';
import PrePaidCardActivate from '../pages/prePaidCardActivate';
import PrepaidCard from '../pages/prepaidCard';
import NewLoan from '../pages/newLoan';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import PrivateRoute from './PrivateRoute';
import { validateSession } from '../api/selfieWebService';
import PrePaidCardDetails from '../pages/prePaidCardDetails';
import PrePaidCardDisable from '../pages/prePaidCardDisable';
import PrePaidCardReprint from '../pages/prePaidCardReprint';
import PrePaidCardChangePinPreview from '../pages/prePaidCardChangePinPreview';
import PrePaidCardChangePin from '../pages/prePaidCardChangePin';
import Survey from '../pages/survey';
import LoanInstallmentsAllList from '../pages/loanInstallmentsAllList';
import LoanInstallmentsList from '../pages/loanInstallmentsList';
import FAQs from '../pages/faqs';
import MyPayments from '../pages/myPayments';
import RevolvingLineOffer from '../pages/revolvingLineOffer';
import RevolvingLine from '../pages/revolvingLine';
import RevolvingLineDetail from '../pages/revolvingLineDetail';
import RevolvingLineDetailInstallments from '../pages/revolvingLineDetailInstallments';
import RevolvingLineDetailLoans from '../pages/revolvingLineDetailLoans';
import RevolvingLineDismiss from '../pages/revolvingLineDismiss';
import GeneratePassword from '../pages/generatePassword';
import PushNotificationSubscription from '../pages/pushNotificationSubscription';
import WebAuthnRegister from '../pages/webAuthnRegister';
import NewHome from '../pages/newHome';
import PayInstallmentRouter from './PayInstallmentRouter';

const ProtectedRoutes = () => {
  useEffect(() => validateSession());

  return (
    <Switch>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.pushNotificationSubscription)}>
        <PushNotificationSubscription />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.generatePassword)}>
        <GeneratePassword />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.userProfileKey)}>
        <UserProfile />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.myInstallmentsViewAllKey)}>
        <LoanInstallmentsAllList />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.myInstallmentsKey)}>
        <LoanInstallmentsList />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.myLoansKey)}>
        <LoanInstallments />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.PaymentMethodSelectionOnlineKey)}>
        <PaymentMethodSelectionOnline />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.HowPaymentMethodOnlineKey)}>
        <HowPaymentMethodOnline />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.PaymentDebitCardFormKey)}>
        <PaymentDebitCardForm />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.PaymentMPFormKey)}>
        <PaymentMPForm />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.PaymentStatus)}>
        <PaymentStatus />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.myCardActivateKey)}>
        <PrePaidCardActivate />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.myCardDetailsKey)}>
        <PrePaidCardDetails />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.myCardDisableKey)}>
        <PrePaidCardDisable />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.myCardReprintKey)}>
        <PrePaidCardReprint />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.myCardChangePinKey)}>
        <PrePaidCardChangePin />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.myCardChangePinKeyPreview)}>
        <PrePaidCardChangePinPreview />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.myCardKey)}>
        <PrepaidCard />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.payInstallmentKey)}>
        <PayInstallment />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.payInstallmentKeyNew)}>
        <PayInstallmentRouter />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.sendPaymentReceiptKey)}>
        <PaymentReceipt />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.prePaidLoanKey)}>
        <PrePaidLoan />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.customerFreeDebtKey)}>
        <CustomerFreeDebt />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.loanPaymentVouchersKey)}>
        <LoanPaymentVouchers />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.moreProductsKey)}>
        <OtherServices />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.myBillsKey)}>
        <CustomerInvoices />
      </PrivateRoute>
      <PrivateRoute path={getPathRouteFromKey(i18n.newLoanKey)}>
        <NewLoan />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.questionsKey)}>
        <Claims />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.surveyKey)}>
        <Survey />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.helpMenuKey)}>
        <FAQs />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.homeKey)}>
        <NewHome />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.myPaymentsMenuKey)}>
        <MyPayments />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.revolvingLineOffer)}>
        <RevolvingLineOffer />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.revolvingLine)}>
        <RevolvingLine />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.revolvingLineDetail)}>
        <RevolvingLineDetail />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.revolvingLineDetailInstallments)}>
        <RevolvingLineDetailInstallments />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.revolvingLineDetailLoans)}>
        <RevolvingLineDetailLoans />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.revolvingLineDismiss)}>
        <RevolvingLineDismiss />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.webAuthnRegister)}>
        <WebAuthnRegister />
      </PrivateRoute>
      <PrivateRoute exact path={getPathRouteFromKey(i18n.logoutKey)}>
        <Logout />
      </PrivateRoute>
      <Route>
        <Redirect from="*" to={getPathRouteFromKey(i18n.homeKey)} />
      </Route>
    </Switch>
  );
};

export default ProtectedRoutes;
