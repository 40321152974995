import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import Header from '../../components/common/Header';
import i18n from '../../common/i18n';
import usePayInstallmentController from '../../customHook/controller/usePayInstallmentController';
import Loading from '../../components/common/Loading';
import LoanCardList from './components/LoanCardList';
import { usePayInstallmentContext } from '../../contexts/PayInstallmentContext';
import useHomeNavigation from '../../customHook/useHomeNavigation';
import NoActiveLoan from './components/NoActiveLoan';
import usePayInstallmentStyles from './PayInstallmentStyles';

const PayInstallmentPage = () => {
  const classes = usePayInstallmentStyles();
  const { loading, goToSummary } = usePayInstallmentController();
  const { selectedLoan, loanList } = usePayInstallmentContext();

  const goHome = useHomeNavigation();

  if (loading) return <Loading />;

  if (loanList === undefined || loanList.length === 0) return <NoActiveLoan />;

  return (
    <>
      <Header title={i18n.PayInstallmentPage.title} />
      <Grid container style={{ marginTop: '20px', paddingLeft: '24px', paddingRight: '24px' }} justifyContent="center">
        <Grid item xs={12} md={8} className={classes.gap}>
          <Typography align="left" style={{ fontSize: '16px', fontWeight: 700, marginBottom: '12px' }}>
            {i18n.PayInstallmentPage.selectLoan}
          </Typography>
          <LoanCardList />
          <Box className={classes.ctaContainer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={selectedLoan === undefined}
              onClick={goToSummary}
            >
              {i18n.PayInstallmentPage.continueCTA}
            </Button>
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={goHome}
              style={{ marginTop: '12px' }}
            >
              {i18n.PayInstallmentPage.backCTA}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PayInstallmentPage;
