import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePayInstallmentContext } from '../../contexts/PayInstallmentContext';
import { getLoansToPayInstallments } from '../../api/payInstallmentApiService';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import { PayInstallmentRoutesList } from '../../utils/menuAndRoutesUtil';

const usePayInstallmentController = () => {
  const history = useHistory();
  const { showToast } = useToaster();
  const { setLoanList } = usePayInstallmentContext();
  const [loading, setLoading] = useState(true);

  const init = async () => {
    try {
      setLoading(true);
      const { data: loanList } = await getLoansToPayInstallments();
      setLoanList(loanList);
    } catch (error) {
      showToast(i18n.PayInstallmentPage.getLoansToPayError, 'error');
    } finally {
      setLoading(false);
    }
  };

  const goToSummary = () => {
    history.push(PayInstallmentRoutesList.summary);
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    goToSummary,
  };
};

export default usePayInstallmentController;
