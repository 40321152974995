import i18n from '../common/i18n';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { ReactComponent as HomeIcon } from '../assets/imgs/menu-inicio.svg';
import { ReactComponent as MyLoansIcon } from '../assets/imgs/menu-prestamos.svg';
import { ReactComponent as QRIcon } from '../assets/imgs/menu-qr.svg';
import { ReactComponent as RevolvingLineIcon } from '../assets/imgs/menu-credito.svg';
import { ReactComponent as CardIcon } from '../assets/imgs/menu-card.svg';
import { ReactComponent as PaymentIcon } from '../assets/imgs/menu-mis-pagos.svg';
import { ReactComponent as MenuIcon } from '../assets/imgs/menu-hamburguesa.svg';

export const HOME_OPTIONS = {
  homeNewLoan: {
    title: i18n.homeNewLoan,
    urlImage: '/images/home-nuevo-prestamo.svg',
    pathOnClick: getPathRouteFromKey(i18n.newLoanKey),
  },
  homeMyLoans: {
    title: i18n.homeLoanInstallments,
    urlImage: '/images/home-mis-prestamos.svg',
    pathOnClick: getPathRouteFromKey(i18n.myLoansKey),
  },
  homePayInstallment: {
    title: i18n.homePayInstallment,
    urlImage: '/images/home-pagar-cuota.svg',
    pathOnClick: getPathRouteFromKey(i18n.payInstallmentKey),
  },
  homeMyCard: {
    title: i18n.homeMyCard,
    urlImage: '/images/home-mi-tarjeta.svg',
    pathOnClick: getPathRouteFromKey(i18n.myCardKey),
  },
  homeMyPayments: {
    title: i18n.homeMyPayments,
    urlImage: '/images/home-mis-pagos.svg',
    pathOnClick: getPathRouteFromKey(i18n.myPaymentsMenuKey),
  },
  homePayService: {
    title: i18n.homePayService,
    urlImage: '/images/home-pago-servicios.svg',
    pathOnClick: `${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=91`,
  },
  homeRecharge: {
    title: i18n.homeRecharge,
    urlImage: '/images/home-recargas.svg',
    pathOnClick: `${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=69`,
  },
  homePayQR: {
    title: i18n.homeQRPayment,
    urlImage: '/images/home-pago-qr.svg',
    pathOnClick: getPathRouteFromKey(i18n.paymentQRKey),
  },
};

export const NAVIGATION_MENU_OPTIONS = {
  home: {
    label: i18n.NavigationMenu.home,
    img: HomeIcon,
    path: getPathRouteFromKey(i18n.homeKey),
  },
  myLoans: {
    label: i18n.NavigationMenu.myLoans,
    img: MyLoansIcon,
    path: getPathRouteFromKey(i18n.myLoansKey),
  },
  qr: {
    label: '',
    img: QRIcon,
    path: getPathRouteFromKey(i18n.paymentQRKey),
  },
  revolvingLine: {
    label: i18n.NavigationMenu.revolvingLine,
    img: RevolvingLineIcon,
    path: getPathRouteFromKey(i18n.revolvingLineDetail),
  },
  card: {
    label: i18n.NavigationMenu.card,
    img: CardIcon,
    path: getPathRouteFromKey(i18n.myCardKey),
  },
  myPayments: {
    label: i18n.NavigationMenu.myPayments,
    img: PaymentIcon,
    path: getPathRouteFromKey(i18n.myPaymentsMenuKey),
  },
  menu: {
    label: i18n.NavigationMenu.menu,
    img: MenuIcon,
  },
};

export const DISBURSEMENT_MODES = {
  PREPAID_CARD: 'PREPAID_CARD',
  PAYMENT_QR: 'PAYMENT_QR',
};

export const PRODUCTS_IDS = {
  REVOLVING_LINE: 46,
};
