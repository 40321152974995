import { makeStyles } from '@material-ui/core';

const usePayInstallmentStyles = makeStyles((theme) => ({
  ctaContainer: {
    margin: '24px 0px',
  },

  [theme.breakpoints.down('md')]: {
    ctaContainer: {
      margin: '0px',
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      backgroundColor: 'white',
      width: '100%',
      padding: '32px 24px',
      borderTop: '1px solid #e0e0e0',
      boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
    },

    gap: {
      marginBottom: '150px',
    },
  },
}));

export default usePayInstallmentStyles;
