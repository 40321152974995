/* eslint-disable arrow-body-style */
const PayInstallmentSummaryPage = () => {
  return (
    <>
      SUMMARY
    </>
  );
};

export default PayInstallmentSummaryPage;
