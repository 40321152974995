import PropTypes from 'prop-types';
import { useState, createContext, useContext } from 'react';

const PayInstallmentContext = createContext();

export const PayInstallmentProvider = ({ children }) => {
  const [loanList, setLoanList] = useState();
  const [selectedLoan, setSelectedLoan] = useState();

  return (
    <PayInstallmentContext.Provider
      value={{
        loanList,
        setLoanList,
        selectedLoan,
        setSelectedLoan,
      }}
    >
      {children}
    </PayInstallmentContext.Provider>
  );
};

PayInstallmentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePayInstallmentContext = () => useContext(PayInstallmentContext);
